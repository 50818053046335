import { useEffect, useState } from "react";
import "./App.css";
import { Installed } from "./components/Installed/Installed";
import { Main } from "./components/Main/Main";
import { useReactPWAInstall } from "react-pwa-install";

function App() {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const [animating, setAnimating] = useState(0);
  const [megabyte, setMegabyte] = useState(0);

  const param = new URLSearchParams(document.location.search);

  let external_id = param.get("external_id");
  let creative_id = param.get("creative_id");
  let creo_id = param.get("creo_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let source = param.get("source");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let offer = param.get("offer");
  let id = param.get("id");

  if (cost) {
    localStorage.setItem("cost", cost);
  } else {
    cost = localStorage.getItem("cost");
  }
  if (creo_id) {
    localStorage.setItem("creo_id", creo_id);
  } else {
    creo_id = localStorage.getItem("creo_id");
  }
  if (external_id) {
    localStorage.setItem("external_id", external_id);
  } else {
    external_id = localStorage.getItem("external_id");
  }
  if (site_id) {
    localStorage.setItem("site_id", site_id);
  } else {
    site_id = localStorage.getItem("site_id");
  }
  if (creative_id) {
    localStorage.setItem("creative_id", creative_id);
  } else {
    creative_id = localStorage.getItem("creative_id");
  }
  if (ad_campaign_id) {
    localStorage.setItem("ad_campaign_id", ad_campaign_id);
  } else {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }
  if (source) {
    localStorage.setItem("source", source);
  } else {
    source = localStorage.getItem("source");
  }

  if (offer) {
    localStorage.setItem("offer", offer);
  } else {
    offer = localStorage.getItem("offer");
  }
  if (id) {
    localStorage.setItem("id", id);
  } else {
    id = localStorage.getItem("id");
  }
  useEffect(() => {
    if (localStorage.getItem("isAlreadyDownload")) {
      if (id === "1163") {
        window.location.href =
          `https://arumo.top/V8z5NcNb?a=b` +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (creo_id !== null ? `&creative_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (id === "1168") {
        window.location.href =
          `https://arumo.top/QRJpY8p6?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1170") {
        window.location.href =
          `https://arumo.top/qQbcTQ6y?a=b` +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (id === "1166") {
        window.location.href =
          `https://arumo.top/YHrM8CYK?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1245") {
        window.location.href =
          `https://arumo.top/HRqwGbxN?a=b` +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (id === "1246") {
        window.location.href =
          `https://arumo.top/WCRGCgHf?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1248") {
        window.location.href =
          `https://arumo.top/HB6JTNfK?a=b` +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "");
      } else if (offer === "VrjxM2bv") {
        window.location.href =
          `https://arumo.top/VrjxM2bv?ad_campaign_id=${ad_campaign_id}` +
          (creo_id !== null ? `&creative_id=${creo_id}` : "") +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (offer === "BnRFdmsn") {
        window.location.href =
          `https://arumo.top/BnRFdmsn?ad_campaign_id=${ad_campaign_id}` +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (cost !== null ? `&cost=${cost}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else {
        window.location.href =
          `https://arumo.top/V8z5NcNb?a=b` +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (creo_id !== null ? `&creative_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      }
    }
  }, []);

  const animationMegabyte = () => {
    setTimeout(() => {
      setMegabyte(0.82);
    }, 1500);

    setTimeout(() => {
      setMegabyte(0.92);
    }, 2700);

    setTimeout(() => {
      setMegabyte(1.27);
    }, 4200);

    setTimeout(() => {
      setMegabyte(1.63);
    }, 5100);

    setTimeout(() => {
      setMegabyte(1.84);
    }, 6000);

    setTimeout(() => {
      setMegabyte(2.41);
    }, 7800);

    setTimeout(() => {
      setMegabyte(2.93);
    }, 9800);

    setTimeout(() => {
      setMegabyte(3.4);
    }, 11700);

    setTimeout(() => {
      setMegabyte(3.7);
    }, 13600);

    setTimeout(() => {
      setMegabyte("Installing...");
    }, 15400);
  };

  const handleClick = () => {
    const userAgent = window.navigator.userAgent;

    if (/iPhone/i.test(userAgent)) {
      if (id === "1163") {
        window.location.href =
          `https://arumo.top/V8z5NcNb?a=b` +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (creo_id !== null ? `&creative_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (id === "1168") {
        window.location.href =
          `https://arumo.top/QRJpY8p6?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1170") {
        window.location.href =
          `https://arumo.top/qQbcTQ6y?a=b` +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (id === "1166") {
        window.location.href =
          `https://arumo.top/YHrM8CYK?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1245") {
        window.location.href =
          `https://arumo.top/HRqwGbxN?a=b` +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (id === "1246") {
        window.location.href =
          `https://arumo.top/WCRGCgHf?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1248") {
        window.location.href =
          `https://arumo.top/HB6JTNfK?a=b` +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "");
      } else if (offer === "VrjxM2bv") {
        window.location.href =
          `https://arumo.top/VrjxM2bv?ad_campaign_id=${ad_campaign_id}` +
          (creo_id !== null ? `&creative_id=${creo_id}` : "") +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (offer === "BnRFdmsn") {
        window.location.href =
          `https://arumo.top/BnRFdmsn?ad_campaign_id=${ad_campaign_id}` +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (cost !== null ? `&cost=${cost}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else {
        window.location.href =
          `https://arumo.top/V8z5NcNb?a=b` +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (creo_id !== null ? `&creative_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      }
    } else {
      pwaInstall({
        title: "Install Web App",
      })
        .then(() => {
          setAnimating(true);
          animationMegabyte();
          setTimeout(() => {
            localStorage.setItem("isAlreadyDownload", true);
            setAnimating("done");
          }, 17400);

          async function handleNotifications() {
            try {
              const permissionResult = await Notification.requestPermission();
              if (permissionResult === "granted") {
              } else {
                console.log("Notification permission denied");
              }
            } catch (error) {
              console.error("Error requesting notification permission:", error);
            }
            setAnimating("done");
          }

          setTimeout(() => {
            handleNotifications();
          }, 17700);
        })
        .catch(() => {
          setTimeout(() => {
            if (id === "1163") {
              window.location.href =
                `https://arumo.top/V8z5NcNb?a=b` +
                (external_id !== null ? `&external_id=${external_id}` : "") +
                (creo_id !== null ? `&creative_id=${creo_id}` : "") +
                (ad_campaign_id !== null
                  ? `&ad_campaign_id=${ad_campaign_id}`
                  : "") +
                (site_id !== null ? `&site_id=${site_id}` : "");
            } else if (id === "1168") {
              window.location.href =
                `https://arumo.top/QRJpY8p6?a=b` +
                (creative_id !== null ? `&creative_id=${creative_id}` : "") +
                (ad_campaign_id !== null
                  ? `&ad_campaign_id=${ad_campaign_id}`
                  : "") +
                (source !== null ? `&source=${source}` : "");
            } else if (id === "1170") {
              window.location.href =
                `https://arumo.top/qQbcTQ6y?a=b` +
                (external_id !== null ? `&external_id=${external_id}` : "") +
                (creo_id !== null ? `&creo_id=${creo_id}` : "") +
                (ad_campaign_id !== null
                  ? `&ad_campaign_id=${ad_campaign_id}`
                  : "") +
                (site_id !== null ? `&site_id=${site_id}` : "");
            } else if (id === "1166") {
              window.location.href =
                `https://arumo.top/YHrM8CYK?a=b` +
                (creative_id !== null ? `&creative_id=${creative_id}` : "") +
                (source !== null ? `&source=${source}` : "");
            } else {
              window.location.href =
                `https://arumo.top/V8z5NcNb?a=b` +
                (external_id !== null ? `&external_id=${external_id}` : "") +
                (creo_id !== null ? `&creative_id=${creo_id}` : "") +
                (ad_campaign_id !== null
                  ? `&ad_campaign_id=${ad_campaign_id}`
                  : "") +
                (site_id !== null ? `&site_id=${site_id}` : "");
            }

            if (offer === "VrjxM2bv") {
              window.location.href =
                `https://arumo.top/VrjxM2bv?ad_campaign_id=${ad_campaign_id}` +
                (creo_id !== null ? `&creative_id=${creo_id}` : "") +
                (external_id !== null ? `&external_id=${external_id}` : "") +
                (site_id !== null ? `&site_id=${site_id}` : "");
            } else {
              window.location.href =
                `https://arumo.top/BnRFdmsn?ad_campaign_id=${ad_campaign_id}` +
                (creo_id !== null ? `&creo_id=${creo_id}` : "") +
                (external_id !== null ? `&external_id=${external_id}` : "") +
                (cost !== null ? `&cost=${cost}` : "") +
                (site_id !== null ? `&site_id=${site_id}` : "");
            }
          }, 400);
        });
    }
    setTimeout(() => {
      document.querySelector(".MuiButton-containedPrimary").click();
    }, 5);
  };

  return (
    <div className="App">
      {supported() && !isInstalled() ? (
        <Main
          handleClick={handleClick}
          animating={animating}
          setAnimating={setAnimating}
          megabyte={megabyte}
          supported={true}
        />
      ) : isInstalled() ? (
        <Installed />
      ) : (
        <>
          <Main
            handleClick={handleClick}
            animating={animating}
            setAnimating={setAnimating}
            megabyte={megabyte}
            supported={false}
          />
        </>
      )}
    </div>
  );
}

export default App;
