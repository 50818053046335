export const Installed = () => {
  const param = new URLSearchParams(document.location.search);

  let source = param.get("source");
  let creative_id = param.get("creative_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let offer = param.get("offer");
  let creo_id = param.get("creo_id");
  let external_id = param.get("external_id");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let id = param.get("id");

  if (!cost) {
    cost = localStorage.getItem("cost");
  }

  if (!creo_id) {
    creo_id = localStorage.getItem("creo_id");
  }

  if (!external_id) {
    external_id = localStorage.getItem("external_id");
  }

  if (!site_id) {
    site_id = localStorage.getItem("site_id");
  }

  if (!source) {
    source = localStorage.getItem("source");
  }

  if (!creative_id) {
    creative_id = localStorage.getItem("creative_id");
  }

  if (!ad_campaign_id) {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }

  if (!offer) {
    offer = localStorage.getItem("offer");
  }

  if (!id) {
    id = localStorage.getItem("id");
  }

  setTimeout(() => {
    if (id === "1163") {
      window.location.href =
        `https://arumo.top/V8z5NcNb?a=b` +
        (external_id !== null ? `&external_id=${external_id}` : "") +
        (creo_id !== null ? `&creative_id=${creo_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (site_id !== null ? `&site_id=${site_id}` : "");
    } else if (id === "1168") {
      window.location.href =
        `https://arumo.top/QRJpY8p6?a=b` +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    } else if (id === "1170") {
      window.location.href =
        `https://arumo.top/qQbcTQ6y?a=b` +
        (external_id !== null ? `&external_id=${external_id}` : "") +
        (creo_id !== null ? `&creo_id=${creo_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (site_id !== null ? `&site_id=${site_id}` : "");
    } else if (id === "1166") {
      window.location.href =
        `https://arumo.top/YHrM8CYK?a=b` +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    } else if (id === "1245") {
      window.location.href =
        `https://arumo.top/HRqwGbxN?a=b` +
        (external_id !== null ? `&external_id=${external_id}` : "") +
        (creo_id !== null ? `&creo_id=${creo_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (site_id !== null ? `&site_id=${site_id}` : "");
    } else if (id === "1246") {
      window.location.href =
        `https://arumo.top/WCRGCgHf?a=b` +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    } else if (id === "1248") {
      window.location.href =
        `https://arumo.top/HB6JTNfK?a=b` +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "");
    } else if (offer === "VrjxM2bv") {
      window.location.href =
        `https://arumo.top/VrjxM2bv?ad_campaign_id=${ad_campaign_id}` +
        (creo_id !== null ? `&creative_id=${creo_id}` : "") +
        (external_id !== null ? `&external_id=${external_id}` : "") +
        (site_id !== null ? `&site_id=${site_id}` : "");
    } else if (offer === "BnRFdmsn") {
      window.location.href =
        `https://arumo.top/BnRFdmsn?ad_campaign_id=${ad_campaign_id}` +
        (creo_id !== null ? `&creo_id=${creo_id}` : "") +
        (external_id !== null ? `&external_id=${external_id}` : "") +
        (cost !== null ? `&cost=${cost}` : "") +
        (site_id !== null ? `&site_id=${site_id}` : "");
    } else {
      window.location.href =
        `https://arumo.top/V8z5NcNb?a=b` +
        (external_id !== null ? `&external_id=${external_id}` : "") +
        (creo_id !== null ? `&creative_id=${creo_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (site_id !== null ? `&site_id=${site_id}` : "");
    }
  }, 200);

  setTimeout(() => {
    document.getElementById("openApp").click();
  }, 17400);

  return <div></div>;
};
